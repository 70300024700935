import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Button } from '@abyss/web/ui/Button';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import InlinePopover from './InlinePopover';
import { Table } from '@abyss/web/ui/Table';
import { useCollapse } from '@abyss/web/hooks/useCollapse';
import { SS_Campaign, SS_MemberQuest } from '../constants/challengeTypes';
import { isoDateTimeToIsoDate, isoDateTimeToIsoDateTime } from '../helpers/utils';
import { floatToPercent } from '../helpers/intUtils';
import SubChallengeActivityView from './SubChallengeActivityView';
import SubChallengeView from './SubChallengeView';
import { Cell } from '../styles/styles';

export interface SubChallengeViewQuestRowProps {
    campaign: SS_Campaign;
    quest: SS_MemberQuest;
};

const SubChallengeViewQuestRow: React.FC<SubChallengeViewQuestRowProps> = ({ campaign, quest }) => {
    const { buttonProps, isOpen } = useCollapse({ defaultIsOpen: false });

    const [isDay, isDailyFitness] = useMemo(() => {
        const isDailyFitness = quest.qd?.trackerType === 'Daily' && quest.qd?.calculationActivity !== 'QuestCompletion' && quest.qd?.category === 'Fitness';
        const isDay = quest.qd?.duration === 'Day';
        return [isDay, isDailyFitness];
    }, [quest]);

    const isDailyDefault = campaign.campaignType === 'DailyDefault';

    return (
        <>
            <Table.Row key={quest.questId}>
                {(isDailyDefault || isDailyFitness) && <>
                    <Cell $selected={isOpen}>{quest.qd?.name}</Cell>
                    <Cell $selected={isOpen}>{quest.qd?.calculationActivity}</Cell>
                    <Cell $selected={isOpen}>{quest.qd?.goal}</Cell>
                </>}
                {isDay && <>
                    <Cell $selected={isOpen}>{isoDateTimeToIsoDate(quest.startDateTime)}</Cell>
                </>}
                {!isDay && <>
                    <Cell $selected={isOpen}>{isoDateTimeToIsoDate(quest.startDateTime)}</Cell>
                    <Cell $selected={isOpen}>{isoDateTimeToIsoDate(quest.skEndDateTime)}</Cell>
                </>}
                <Cell $semibold $selected={isOpen}>{isoDateTimeToIsoDate(quest.mqp?.completionDateTime ?? '-')}</Cell>
                <Cell $selected={isOpen}>{
                    isoDateTimeToIsoDateTime(quest.mqr?.rallyPostDateTime ?? '-')}
                    <InlinePopover width={500} displayed={quest.mqr?.rallyPostDateTime ? true : false} content={
                        <>
                            <div><strong>Rally Transaction ID:</strong> {quest.mqr?.rallyTransactionId}</div>
                            <div><strong>Rally Post Date:</strong> {quest.mqr?.rallyPostDateTime}</div>
                            <div><strong>Reported Completion Date:</strong> {quest.mqr?.reportedCompletionDateTime}</div>
                        </>
                    }/>
                </Cell>
                <Cell $selected={isOpen}>{isoDateTimeToIsoDateTime(quest.mqp?.lastCalculationDateTime)}</Cell>
                <Cell $selected={isOpen}>{floatToPercent(quest.mqp?.progressPercent ?? 0)}</Cell>
                <Cell $button $selected={isOpen}>
                    {(quest.quests.length > 0 || quest.activities.length > 0) &&
                        <TableButtonWrapper>
                            <Button variant="ghost" rounded size="$xs" {...buttonProps}>
                                <IconMaterial icon={isOpen ? 'expand_less' : 'expand_more'} size='$md' variant='outlined' />
                            </Button>
                        </TableButtonWrapper>
                    }
                </Cell>
            </Table.Row>
            {isOpen && quest.quests.length > 0 &&
                <Table.Row>
                    <Table.Cell colSpan={100}>
                        <SubChallengeView quest={quest} campaign={campaign}/>
                    </Table.Cell>
                </Table.Row>
            }
            {isOpen && quest.activities.length > 0 &&
                <Table.Row key={quest.questId + 'a'}>
                    <Table.Cell colSpan={100}>
                        <SubChallengeActivityView quest={quest} />
                    </Table.Cell>
                </Table.Row>
            }
        </>
    );
};

const TableButtonWrapper = styled.div`
    line-height: 0px;
`;

export default SubChallengeViewQuestRow;
