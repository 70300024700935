import styled, { css } from 'styled-components';
import { Table } from '@abyss/web/ui/Table';

type CellProps = {
    $divided: boolean;
    $bold: boolean;
    $semibold: boolean;
    $header: boolean;
    $transparent: boolean;
    $selected: boolean;
    $button: boolean;
}

export const HeaderRow = styled(Table.TableHeaderRow)`
    background-color: ${props => props.$transparent ? 'initial' : 'var(--abyss-colors-gray2)'};
`;


const fixedStyle = () => css`
    border: none !important;
    color: var(--abyss-colors-foreground);
    padding: var(--abyss-space-sm);
    font-size: var(--abyss-fontSizes-sm);
    height: inherit;
`;

const dynamicStyle = (props: CellProps) => css`
    border-right: ${props.$divided && '1px solid var(--abyss-colors-gray4) !important'};
    background-color: ${props.$transparent && 'transparent'};
    background-color: ${props.$selected && 'var(--abyss-colors-tint2)'};
    padding: ${props.$button && 'var(--abyss-space-xs)'};
`;

export const BaseCell = styled(Table.Cell)`
    border: none !important;
    color: var(--abyss-colors-foreground);
    background-color: var(--abyss-colors-primary2);
    padding: var(--abyss-space-sm);
    font-size: var(--abyss-fontSizes-sm);
    height: inherit;
`;

//include? background-color: var(--abyss-colors-primary2);
export const Cell = styled(Table.Cell)`
    ${fixedStyle}
    font-weight: ${props => (props.$bold || props.$header) && 'var(--abyss-fontWeights-bold)'};
    font-weight: ${props => props.$semibold && 'var(--abyss-fontWeights-semibold)'};
    background-color: ${props => props.$header && 'var(--abyss-colors-gray2)'};
    ${dynamicStyle}
`;

export const HeaderCell = styled(Table.Column)`
    ${fixedStyle}
    font-weight: var(--abyss-fontWeights-bold);
    background-color: ${props => props.$header && 'var(--abyss-colors-gray2)'};
    ${dynamicStyle}
`;

export const TableWrapper = styled.div`
    border-top: 2.5px solid black;
    padding-bottom: 10px;
    width: fit-content;
`;

export const TopMarginWrapper = styled.div`
    margin-top: var(--abyss-space-sm);
`;

export const TopMarginBlockWrapper = styled(TopMarginWrapper)`
    display: block;
`;
