import { Card } from '@abyss/web/ui/Card';
import { Table } from '@abyss/web/ui/Table';
import { DemographicsModel } from '../constants/types';
import { isoDateTimeToIsoDate, isoDateTimeToIsoDateTime, isoDateTimeToReadableDate } from '../helpers/utils';
import InlinePopover from './InlinePopover';
import { Cell, HeaderCell, HeaderRow } from '../styles/styles';

export interface DemographicsCardProps {
    member: DemographicsModel;
};

const DemographicsCard: React.FC<DemographicsCardProps> = ({ member }) => {
    const { firstName, lastName, dob, registeredDate, gender, policyId, planName, lob, programStartDate, programEndDate } = member;
    return (
        <Card size='small'>
            <Table.Container title='Member Demographics Table'>
                <Table.TableHeader>
                    <HeaderRow>
                        <HeaderCell>First Name</HeaderCell>
                        <HeaderCell>Last Name</HeaderCell>
                        <HeaderCell>Date of Birth</HeaderCell>
                        <HeaderCell>Registered Date</HeaderCell>
                        <HeaderCell>Gender</HeaderCell>
                    </HeaderRow>
                </Table.TableHeader>
                <Table.TableBody>
                    <Table.Row>
                        <Cell>{firstName ?? '-'}</Cell>
                        <Cell>{lastName ?? '-'}</Cell>
                        <Cell>{isoDateTimeToReadableDate(dob) ?? '-'}</Cell>
                        <Cell>
                            {isoDateTimeToReadableDate(registeredDate) ?? '-'}
                            <InlinePopover content={isoDateTimeToIsoDateTime(registeredDate)}/>
                        </Cell>
                        <Cell>{gender ?? '-'}</Cell>
                    </Table.Row>
                </Table.TableBody>
            </Table.Container>
            <Table.Container title='Plan Details Table'>
                <Table.TableHeader>
                    <HeaderRow>
                        <HeaderCell>Policy ID</HeaderCell>
                        <HeaderCell>Plan Name</HeaderCell>
                        <HeaderCell>Line of Business</HeaderCell>
                        <HeaderCell>Program Dates</HeaderCell>
                    </HeaderRow>
                </Table.TableHeader>
                <Table.TableBody>
                    <Table.Row>
                        <Cell>{policyId ?? '-'}</Cell>
                        <Cell>{planName ?? '-'}</Cell>
                        <Cell>{lob ?? '-'}</Cell>
                        <Cell>{isoDateTimeToIsoDate(programStartDate)} - {isoDateTimeToIsoDate(programEndDate)}</Cell>
                    </Table.Row>
                </Table.TableBody>
            </Table.Container>
        </Card>
    );
};

export default DemographicsCard;
