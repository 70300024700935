 import React from 'react';
import { DataTable } from '@abyss/web/ui/DataTable';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Badge } from '@abyss/web/ui/Badge';
import { Drawer } from '@abyss/web/ui/Drawer';
import { Link } from '@abyss/web/ui/Link';
import { Layout } from '@abyss/web/ui/Layout';
import { useOverlay } from '@abyss/web/hooks/useOverlay';
import { useDataTable } from '@abyss/web/hooks/useDataTable';
import { GitHubProgress, Progress, Variants, FRONTEND } from '../constants/constants';
import getAllRepositories from '../api/githubApi';
import { LoadingOverlay } from '@abyss/web/ui/LoadingOverlay';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function PRDashboard({ inputToken, isLoading, setLoading }: any) {
    const input = inputToken.value;
    React.useEffect(() => {
        async function fetchData() {
            const result = await createData(input);
            dataTableProps.setData(result, true);
            setLoading(false);
        }
        fetchData();
    }, []);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const getProgress = (values: any) => {
        const first = values[0] ? values[0].state : 'OPEN';
        const second = values[1] ? values[1].state : 'OPEN';

        if (first === GitHubProgress.APPROVED && second === GitHubProgress.APPROVED) {
            return Progress.APPROVED;
        } else if (first === GitHubProgress.CHANGES || second === GitHubProgress.CHANGES) {
            return Progress.CHANGES;
        } else {
            return Progress.NEEDS_APPROVALS;
        }
    };

    const getVariant = (value: string) => {
        let label = Variants.NEUTRAL;
        if (value === Progress.APPROVED) {
            label = Variants.SUCCESS;
        } else if (value === Progress.CHANGES) {
            label = Variants.ERROR;
        } else if (value === Progress.NEEDS_APPROVALS) {
            label = Variants.WARNING;
        }

        return label;
    };

    const getIcon = (value: string) => {
        if (value === Progress.APPROVED) {
            return <IconMaterial icon='check' color='green' size='24px' />;
        } else if (value === Progress.NEEDS_APPROVALS) {
            return <IconMaterial icon='warning' color='orange' size='24px' />;
        } else if (value === Progress.CHANGES) {
            return <IconMaterial icon='block' color='red' size='24px' />;
        }
    };

    function formatDate(dateString: string): string {
        const date = new Date(dateString);
        const month = date.getMonth() + 1;
        const day = date.getDate();
        const year = date.getFullYear();

        return `${month}/${day}/${year}`;
    }

    async function createData(input: string) {
        const data = await getAllRepositories(null, input);
        const nestedData = processData(data);
        const flattenedData = nestedData && nestedData?.reduce((acc, value) => acc.concat(value), []);

        return flattenedData;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function processData(repositories: any): [] {
        const pullRequestsByRepository =
            repositories &&
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            repositories.map((repository: any) => {
                const prNodes = repository.pullRequests.nodes;

                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const pullRequests = prNodes.map((prNode: any) => ({
                    colPullRequestName: [prNode.title, prNode.url],
                    colRepositoryName: repository.name,
                    colCreationDate: prNode.createdAt,
                    colUpdateDate: prNode.updatedAt,
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    colProgress: [getProgress(prNode.reviews.nodes), prNode.reviewRequests.nodes.map((node: any) => node.requestedReviewer.login)],
                    colAuthorName: prNode.author.login,
                    colRepoType: FRONTEND.includes(repository.name) ? 'FE' : 'BE'
                }));

                return pullRequests;
            });

        return pullRequestsByRepository;
    }

    const columns = React.useMemo(
        () => [
            {
                Header: (
                    <React.Fragment>
                        <div>Pull Request Name</div>
                    </React.Fragment>
                ),
                accessor: 'colPullRequestName',
                canToggleVisibilty: false,
                isHiddenByDefault: false,
                minWidth: 450,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                Cell: ({ value }: any) => {
                    return <Link href={value[1]}>{value[0]}</Link>;
                }
            },
            {
                Header: 'Repository Name',
                accessor: 'colRepositoryName',
                canToggleVisibilty: false,
                isHiddenByDefault: false,
                canReorderColumn: false,
                minWidth: 200
            },
            {
                Header: 'Date Created',
                accessor: 'colCreationDate',
                canToggleVisibilty: false,
                isHiddenByDefault: false,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                Cell: ({ value }: any) => {
                    return formatDate(value);
                }
            },
            {
                Header: 'Date Last Updated',
                accessor: 'colUpdateDate',
                canToggleVisibilty: false,
                isHiddenByDefault: false,
                disableSortBy: false,
                canReorderColumn: false,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                Cell: ({ value }: any) => {
                    return formatDate(value);
                }
            },
            {
                Header: 'Progress',
                accessor: 'colProgress',
                canToggleVisibilty: false,
                isHiddenByDefault: false,
                disableSortBy: false,
                canReorderColumn: false,
                width: 200,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                Cell: ({ value }: any) => {
                    const reviewers = value[1] && value[1]?.length === 0 ? ['No Reviewers Assigned'] : value[1];
                    const badgeLabel = value[0];
                    const badgeColor = getVariant(badgeLabel);
                    const iconMaterial = getIcon(badgeLabel);

                    return (
                        <Layout.Group>
                            <Link size='$sm' onClick={() => drawer.open({ reviewers })} aria-haspopup='dialog'>
                                {iconMaterial}
                                <Badge variant={badgeColor}>{badgeLabel}</Badge>
                            </Link>
                        </Layout.Group>
                    );
                }
            },
            {
                Header: 'Author',
                accessor: 'colAuthorName',
                canToggleVisibilty: false,
                isHiddenByDefault: false,
                disableSortBy: false,
                canReorderColumn: false,
                disableResizing: true
            },
            {
                Header: 'Type',
                accessor: 'colRepoType',
                canToggleVisibilty: false,
                isHiddenByDefault: false,
                disableSortBy: false,
                canReorderColumn: false,
                minWidth: 100,
                width: 100,
                disableResizing: true
            }
        ],
        []
    );

    const dataTableProps = useDataTable({
        initialData: [],
        initialColumns: columns,

        showSelection: false,
        showPagination: true,
        showGlobalFilter: true,
        showTableSettings: true,
        showFullscreenButton: false,
        showDownloadButton: false,
        showFilterDataset: true,
        showColumnVisibilityConfig: false,
        initialFilters: [
            {
                columnId: 'colAuthorName',
                filters: [
                    {
                        condition: 'not-equal',
                        filterValue: 'dependabot'
                    }
                ]
            }
        ],

        uniqueStorageId: 'data-table-usage'
    });

    const drawer = useOverlay('Reviewers Assigned');
    const { data } = drawer.getState();

    return (
        <React.Fragment>
            {isLoading && (
                <div style={{ marginTop: '120px' }}>
                    <LoadingOverlay
                        loadingTitle='Loading...'
                        loadingMessage={`Retrieving Pull Requests`}
                        isLoading={isLoading}
                        isDismissable
                        style={{ background: 'transparent !important' }}></LoadingOverlay>
                </div>
            )}

            {!isLoading && <DataTable title='Pull Request Dashboard' tableState={dataTableProps} hideTitleHeader />}
            <Drawer title='Pending Reviewers' model='Reviewers Assigned'>
                <Drawer.Section>
                    {data &&
                        data.reviewers.map((item: string) => {
                            return (
                                <p key={item} style={{ marginBottom: 30 }}>
                                    {item}
                                </p>
                            );
                        })}
                </Drawer.Section>
            </Drawer>
        </React.Fragment>
    );
}
