import { ApolloClient, InMemoryCache, gql, HttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

const httpLink = new HttpLink({
    uri: 'https://api.github.com/graphql'
});
export default function getAllRepositories(cursor: string | null, token: string): Promise<object> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const authLink = setContext((_: any, { headers }: any) => {
        // return the headers to the context so httpLink can read them
        return {
            headers: {
                ...headers,
                authorization: token ? `Bearer ${token}` : ''
            }
        };
    });

    const client = new ApolloClient({
        link: authLink.concat(httpLink),
        cache: new InMemoryCache()
    });
    return client
        .query({
            query: gql`
                query getAllRepositories($cursor: String) {
                    organization(login: "uhg-internal") {
                        team(slug: "navi_admins") {
                            name
                            repositories(first: 50, after: $cursor) {
                                nodes {
                                    name
                                    pullRequests(states: OPEN, first: 20, orderBy: { field: CREATED_AT, direction: DESC }) {
                                        nodes {
                                            title
                                            url
                                            state
                                            author {
                                                login
                                            }
                                            createdAt
                                            updatedAt
                                            reviews(last: 3) {
                                                nodes {
                                                    state
                                                }
                                            }
                                            reviewRequests(first: 10) {
                                                nodes {
                                                    requestedReviewer {
                                                        ... on User {
                                                            name
                                                            login
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                pageInfo {
                                    endCursor
                                    hasNextPage
                                }
                            }
                        }
                    }
                }
            `,
            variables: { cursor }
        })
        .then((result) => {
            const repositories = result?.data?.organization?.team?.repositories?.nodes;
            const pageInfo = result?.data?.organization?.team?.repositories?.pageInfo;

            // If there are more pages to retrieve, recursively call the function with a new cursor value.
            if (pageInfo?.hasNextPage) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                return getAllRepositories(pageInfo?.endCursor, token).then((nextRepositories: any) => [...repositories, ...nextRepositories]);
            }

            return repositories;
        });
}
