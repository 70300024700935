import axios from 'axios';
import { FC, ChangeEvent, useState } from 'react';
import { createRoot, Root } from 'react-dom/client';
import { useAuth } from 'react-oidc-context';
import { useForm } from '@abyss/web/hooks/useForm';
import { useToast } from '@abyss/web/hooks/useToast';
import { ThemeProvider } from '@abyss/web/ui/ThemeProvider';
import { Layout } from '@abyss/web/ui/Layout';
import { DrawerMenu } from '@abyss/web/ui/DrawerMenu';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { SelectInput } from '@abyss/web/ui/SelectInput';
import { TextInput } from '@abyss/web/ui/TextInput';
import { DateInputRange } from '@abyss/web/ui/DateInputRange';
import { CheckboxTree } from '@abyss/web/ui/CheckboxTree';
import { Button } from '@abyss/web/ui/Button';
import { Tooltip } from '@abyss/web/ui/Tooltip';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import TableResults from '../components/TableResults';
import { ActivityType, DataType } from '../constants/challengeTypes';
import { MemberIdType } from '../constants/types';
import { autoIdType } from '../helpers/utils';
import { TopMarginWrapper } from '../styles/styles';

//global variable for root
let root: Root;

// export default function RawDataSearchForm() {
const RawDataSearchForm: FC = () => {
    const auth = useAuth();
    const token = auth.user?.access_token;
    const { toast } = useToast();

    const defaultSearchValues = {
        idType: MemberIdType.RALLY_ID,
        dataType: [DataType.M, DataType.RR, DataType.MD, DataType.MP, DataType.MMD, DataType.MQ, DataType.MQC, DataType.MQD, DataType.MQP, DataType.MQR, DataType.MCF, DataType.MCP],
    };

    const form = useForm({
        defaultValues: defaultSearchValues,
    });

    const selectedDataTypes = form.watch('dataType');
    const selectedDates = form.watch('dates');

    const dataTypes = [
        {
        value: 'all',
        label: 'All',
        children: [
            {
                value: 'Member Details',
                label: 'Member Details',
                children: [
                    {
                        value: DataType.M,
                        label: 'Member',
                    },
                    {
                        value: DataType.RR,
                        label: 'Rally Relationship',
                    },
                    {
                        value: DataType.ER,
                        label: 'Enterprise ID Relationship',
                    },
                    {
                        value: DataType.MP,
                        label: 'Member Plan',
                    },
                    {
                        value: DataType.MMD,
                        label: 'Member MetaData',
                    }
                ],
            },
            {
                value: 'Device Details',
                label: 'Device Details',
                children: [
                    {
                        value: DataType.MD,
                        label: 'Member Device',
                    },
                    {
                        value: DataType.DE,
                        label: 'Device Events',
                    }
                ],
            },
            {
                value: 'Quests',
                label: 'Quests',
                children: [
                    {
                    value: DataType.QD,
                    label: 'Quest Definition',
                    },
                    {
                    value: DataType.MQ,
                    label: 'Member Quest',
                    },
                    {
                    value: DataType.MQC,
                    label: 'Member Quest Calculation',
                    },
                    {
                    value: DataType.MQD,
                    label: 'Member Quest Display',
                    },
                    {
                    value: DataType.MQP,
                    label: 'Member Quest Progress',
                    },
                    {
                    value: DataType.MQR,
                    label: 'Member Quest Reward',
                    }
                ],
            },
            {
                value: 'Campaigns',
                label: 'Campaigns',
                children: [
                    {
                    value: DataType.SD,
                    label: 'Saga Definition',
                    },
                    {
                    value: DataType.CD,
                    label: 'Campaign Definition',
                    },
                    {
                    value: DataType.MCF,
                    label: 'Member Campaign Focus',
                    },
                    {
                    value: DataType.MCP,
                    label: 'Member Campaign Progress',
                    }
                ],
            },
            {
                value: 'Activity',
                label: 'Activity',
                children: [
                    {
                        value: DataType.AR,
                        label: 'Attestation Record',
                    },
                    {
                        value: DataType.GT,
                        label: 'Garmin Transaction',
                    }
                ],
            }
        ],
        },
    ];

    const clearResults = () => {
        //create root to hold search tables
        const container = document.getElementById('searchTableID');
        root = createRoot(container as HTMLElement);

        //render different tables for each data type
        root.render(
            <>
                <ThemeProvider>
                </ThemeProvider>
            </>
        );
    };

    const resetForm = () => {
        form.reset(defaultSearchValues)
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async function  onSearchSubmit(data : any, e : Event) {
        toast.clean();
        setIsLoading(true);
        e.preventDefault();
        clearResults();

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const requestData = `idType=${data.idType}&Search_Input=${data.idValue}${data.dates?.from ? `&Start_Date=${data.dates.from.replace(/(\d{2})\/(\d{2})\/(\d{4})/g, '$3-$1-$2')}` : ''}${data.dates?.to ? `&End_Date=${data.dates.to.replace(/(\d{2})\/(\d{2})\/(\d{4})/g, '$3-$1-$2')}` : ''}${data.activityType ? `&AE=on&activityType=${data.activityType}` : ''}&${data.dataType.map((t : any) => `${t}=on`).join('&')}`;

        await axios.post(`${process.env.REACT_APP_INVOKE_URL}/searchQueryAPI`,
            requestData, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((r: any) => {
            const response = r.data;
            //store different data type rows in different arrays for different tables
            //Quest Definition
            const QDArr = [];
            //Member
            const MArr = [];
            //Rally Relationship
            const RRArr = [];
            //Member Device
            const MDArr = [];
            //Member Plan
            const MPArr = [];
            //Member MetaData
            const MMDArr = [];
            //Enterprise Relationship
            const ERArr = [];
            //Member Quest
            const MQArr = [];
            //Member Quest Calculation
            const MQCArr = [];
            //Member Quest Display
            const MQDArr = [];
            //Member Quest Progress
            const MQPArr = [];
            //Member Quest Reward
            const MQRArr = [];
            //Saga Definition
            const SDArr = [];
            //Campaign Definition
            const CDArr = [];
            //Member Campaign Focus
            const MCFArr = [];
            //Member Campaign Progress
            const MCPArr = [];
            //Device Event
            const DEArr = [];
            //Attestation Record
            const ARArr = [];
            //Garmin Transaction
            const GTArr = [];
            //Activity Event
            const AEArr = [];

            //don't cause error if response returns nothing
            if (response.length == 0) {
                    const searchParams = new URLSearchParams(requestData);
                    const user = searchParams.get('Search_Input');
                    const id= searchParams.get('idType');
                    toast.clean();
                    toast.show({
                        message: `No records found for ${user} under ${id} `,
                        variant: 'error',
                        autoClose: false
                });
            } else {
                //array of data types to make links to their tables on page
                const dtSet = new Set();

                //iterate over parsed response to get separate table information
                for (let i = 0; i < response.length; i++) {
                    const dtString = response[i].pk.substring(0, response[i].pk.indexOf('#'));
                    dtSet.add(dtString);
                    if (dtString == DataType.QD) {
                        QDArr.push(response[i]);
                    } else if (dtString == DataType.M && response[i].sk == 'MemberInformation') {
                        MArr.push(response[i]);
                    } else if (dtString == DataType.RR) {
                        RRArr.push(response[i]);
                    } else if (dtString == DataType.MD) {
                        MDArr.push(response[i]);
                    } else if (dtString == DataType.MP) {
                        MPArr.push(response[i]);
                    } else if (dtString == DataType.MMD || (dtString == DataType.M && response[i].sk == DataType.MMD)) {
                        MMDArr.push(response[i]);
                    } else if (dtString == DataType.ER) {
                        ERArr.push(response[i]);
                    } else if (dtString == DataType.MQ) {
                        MQArr.push(response[i]);
                    } else if (dtString == DataType.MQC) {
                        MQCArr.push(response[i]);
                    } else if (dtString == DataType.MQD) {
                        MQDArr.push(response[i]);
                    } else if (dtString == DataType.MQP) {
                        MQPArr.push(response[i]);
                    } else if (dtString == DataType.MQR) {
                        MQRArr.push(response[i]);
                    } else if (dtString == DataType.SD) {
                        SDArr.push(response[i]);
                    } else if (dtString == DataType.CD) {
                        CDArr.push(response[i]);
                    } else if (dtString == DataType.MCF) {
                        MCFArr.push(response[i]);
                    } else if (dtString == DataType.MCP) {
                        MCPArr.push(response[i]);
                    } else if (dtString == DataType.AR) {
                        ARArr.push(response[i]);
                    } else if (dtString == DataType.GT) {
                        GTArr.push(response[i]);
                    } else if (dtString == DataType.AE) {
                        AEArr.push(response[i]);
                    } else if (dtString == DataType.DE) {
                        DEArr.push(response[i]);
                    } else {
                        console.log(`Unknown row type found on query: ${response[i]}`);
                    }
                }
                //create root to hold search tables
                const container = document.getElementById('searchTableID');
                root = createRoot(container as HTMLElement);

                const dtSetMenu = Array.from(dtSet).map((dt) => {
                    return {
                        title: dt,
                        href: `#${dt}-dt`
                    }
                });

                //render different tables for each data type
                root.render(
                    <ThemeProvider>
                        <div style={{position: 'fixed', right: '0', top: '30%', maxWidth: '100px', zIndex: '1000'}}>
                            <DrawerMenu menuItems={Array.from(dtSetMenu)} />
                        </div>
                        <TableResults props={MArr} currType={DataType.M} />
                        <TableResults props={RRArr} currType={DataType.RR} />
                        <TableResults props={ERArr} currType={DataType.ER} />
                        <TableResults props={MMDArr} currType={DataType.MMD} />
                        <TableResults props={MDArr} currType={DataType.MD} />
                        <TableResults props={MPArr} currType={DataType.MP} />
                        <TableResults props={SDArr} currType={DataType.SD} />
                        <TableResults props={CDArr} currType={DataType.CD} />
                        <TableResults props={MCFArr} currType={DataType.MCF} />
                        <TableResults props={MCPArr} currType={DataType.MCP} />
                        <TableResults props={QDArr} currType={DataType.QD} />
                        <TableResults props={MQArr} currType={DataType.MQ} />
                        <TableResults props={MQCArr} currType={DataType.MQC} />
                        <TableResults props={MQPArr} currType={DataType.MQP} />
                        <TableResults props={MQDArr} currType={DataType.MQD} />
                        <TableResults props={MQRArr} currType={DataType.MQR} />
                        <TableResults props={DEArr} currType={DataType.DE} />
                        <TableResults props={AEArr} currType={DataType.AE} />
                        <TableResults props={GTArr} currType={DataType.GT} />
                        <TableResults props={ARArr} currType={DataType.AR} />
                    </ThemeProvider>
                );
            }
        })
        .catch(() => {
            alert('Search failed to load, please try again');
        })
        .finally(() => {
            setIsLoading(false);
        });
    };

    const [isLoading, setIsLoading] = useState(false);

    const setFormDates = (start: Date, end: Date) => {
        const to = (end.getMonth() + 1).toString().padStart(2, '0') + '/' + end.getDate().toString().padStart(2, '0') + '/' + end.getFullYear();
        const from = (start.getMonth() + 1).toString().padStart(2, '0') + '/' + start.getDate().toString().padStart(2, '0') + '/' + start.getFullYear();
        form.setValue('dates', { to: to, from: from});
    };

    const setToCurrentMonth = () => {
        const date = new Date();
        setFormDates(
            new Date(date.getFullYear(), date.getMonth(), 1),
            new Date(date.getFullYear(), date.getMonth() + 1, 0)
        );
    };

    const setToCurrentYear = () => {
        const date = new Date();
        setFormDates(
            new Date(date.getFullYear(), 0, 1),
            new Date(date.getFullYear(), 11, 31)
        );
    };

    const setToLast30Days = () => {
        const date = new Date();
        setFormDates(
            new Date(date.getFullYear(), date.getMonth(), date.getDate() - 30),
            new Date(date.getFullYear(), date.getMonth(), date.getDate())
            );
    };

    const setToLast7Days = () => {
        const date = new Date();
        setFormDates(
            new Date(date.getFullYear(), date.getMonth(), date.getDate() - 7),
            new Date(date.getFullYear(), date.getMonth(), date.getDate())
        );
    };

    return (
        <FormProvider state={form} onSubmit={onSearchSubmit} onError={null}>
            <div style={{display: 'block', minHeight: '85px', marginBottom: '5px'}}>
                <div style={{minWidth: '50%', display: 'inline-block', float: 'left', marginRight: '1%'}}>
                    <TextInput
                        label='Member ID'
                        placeholder='Member ID'
                        model='idValue'
                        isClearable
                        onChange={(e : ChangeEvent<HTMLInputElement>) => autoIdType(e.target.value, form)}
                        validators={{ required: true }}
                    />
                </div>
                <div style={{minWidth: '15%', display: 'inline-block'}}>
                    <SelectInput
                        label='ID Type'
                        model='idType'
                        validators={{ required: true }}
                        options={[
                            { label: MemberIdType.RALLY_ID, value: MemberIdType.RALLY_ID },
                            { label: MemberIdType.HSID, value: MemberIdType.HSID },
                            { label: MemberIdType.NAVI_ID, value: MemberIdType.NAVI_ID },
                        ]}
                    />
                </div>
            </div>
            <div style={{display: 'block', height: '100px'}}>
                <div style={{display: 'inline-block', float: 'left'}}>
                    <DateInputRange model='dates' subText='' startDateLabel='Start Date' endDateLabel='End Date' hasClearButton='true'/>
                </div>
                <div style={{display: 'inline-block', marginTop: '10px', marginLeft: '5px'}}>
                    <div style={{display: 'inline-block', width: '150px'}}>
                        <Button type='button' size='$sm' onClick={() => setToCurrentMonth()}>Current Month</Button>
                        <Button type='button' size='$sm' style={{marginTop: '5px'}} onClick={() => setToCurrentYear()}>Current Year</Button>
                    </div>
                    <div style={{display: 'inline-block', width: '135px'}}>
                        <Button type='button' size='$sm' onClick={() => setToLast30Days()}>Last 30 Days</Button>
                        <Button type='button' size='$sm' style={{marginTop: '5px'}} onClick={() => setToLast7Days()}>Last 7 Days</Button>
                    </div>
                </div>
            </div>
            <div style={{display: 'block', marginBottom: '8px'}}>
                <div style={{minWidth: '235px', display: 'inline-block', float: 'left'}}>
                    <SelectInput
                        label='Activity Type'
                        placeholder='(Optional) Pick one'
                        isClearable
                        isSearchable
                        model='activityType'
                        options={[
                            { value: ActivityType.ACTIVE_MINUTES, label: 'Active Minutes' },
                            { value: ActivityType.MODERATE_ACTIVE_MINUTES, label: 'Moderate Active Minutes' },
                            { value: ActivityType.VIGOROUS_ACTIVE_MINUTES, label: 'Vigorous Active Minutes' },
                            { value: ActivityType.CALORIES, label: 'Calories' },
                            { value: ActivityType.DISTANCE, label: 'Distance' },
                            { value: ActivityType.ELEVATION, label: 'Elevation' },
                            { value: ActivityType.FLOORS, label: 'Floors' },
                            { value: ActivityType.STEPS, label: 'Steps' },
                            { value: ActivityType.QUEST_COMPLETION, label: 'Quest Completion' },
                            { value: ActivityType.SLEEP, label: 'Sleep' },
                            { value: ActivityType.STROKE, label: 'Stroke' },
                            { value: ActivityType.PAIR_DEVICE, label: 'Pair Device' },
                            { value: ActivityType.TIME_IN_BED, label: 'Time In Bed' },
                        ]}
                    />
                </div>
                <div style={{minWidth: '350px', display: 'inline-block'}}>
                    <CheckboxTree label='Data Type Filter' nodes={dataTypes} model='dataType' showExpandAll />
                </div>
            </div>
            <Layout.Group>
                {selectedDataTypes.length > 7 && (selectedDates?.to === null || selectedDates?.to === '') && (selectedDates?.from === null || selectedDates?.from === '') ?
                    <Tooltip content='Are you sure you want to search so many data fields without a date range?'>
                        <TopMarginWrapper>
                            <Button type='submit' isLoading={isLoading} ariaLoadingLabel='Searching'>
                                Search
                                <IconMaterial icon='warning' size='18px' style={{ marginLeft: '10px' }}/>
                            </Button>
                        </TopMarginWrapper>
                    </Tooltip>
                    :
                    <TopMarginWrapper>
                        <Button type='submit' isLoading={isLoading} ariaLoadingLabel='Searching'>
                            Search
                        </Button>
                    </TopMarginWrapper>
                }

                <TopMarginWrapper>
                    <Button type='button' onClick={() => resetForm()}>
                        Reset Form
                    </Button>
                </TopMarginWrapper>
                <TopMarginWrapper>
                    <Button type='button' onClick={() => clearResults()}>
                        Clear Results
                    </Button>
                </TopMarginWrapper>
            </Layout.Group>
        </FormProvider>
    );
};

export default RawDataSearchForm;
