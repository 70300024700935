import React, { useMemo } from 'react';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { Table } from '@abyss/web/ui/Table';
import { Cell, HeaderCell, HeaderRow, TableWrapper } from '../styles/styles';
import { sortDeveloperViewHeaders, trimQuotes } from '../helpers/challengeUtils';
import InlinePopover from './InlinePopover';
import CopyButton from './CopyButton';
import { UserPreferenceContext } from '../contexts/UserPreferenceContext';

export interface SubChallengeDeveloperRowProps {
    record: object | undefined;
    pkRename: string;
};

const SubChallengeDeveloperRow: React.FC<SubChallengeDeveloperRowProps> = ({ record, pkRename }) => {
    const ldClient = useLDClient();
    const compactValues = ldClient?.variation('release_ss_compacted_engineer_challenge_view_columns', true) as boolean;
    const { compactDeveloperColumns } = React.useContext(UserPreferenceContext);

    const entries = useMemo(() => {
        if(!record) {
            return [];
        }
        return Object.entries(record).sort((a, b) => sortDeveloperViewHeaders(a[0], b[0])).map((e) => [e[0], trimQuotes(JSON.stringify(e[1]))]);
    }, [record]);
    const entriesValues = useMemo(() => entries.map((columnValue, columnNumber) => {
        let value = <>{columnValue[1]}</>;
        if(compactValues && compactDeveloperColumns) {
            if (columnNumber === 0) {
                const components = columnValue[1].split('#');
                if (components[1] !== 'All') {
                    value = <>{components[0]}#MID<InlinePopover width={425} content={<>{columnValue[1]}<CopyButton value={columnValue[1]} /></>}/></>;
                }
            } else if (columnNumber === 1) {
                const components = columnValue[1].split('#');
                const joinedComponents = components.map((c) => c.startsWith('CD_') ? 'CDID' : c.startsWith('QD_') ? 'QDID' : c.startsWith('MQ_') ? 'MQID' : c ).join('#');
                if (columnValue[1] !== joinedComponents) {
                    value = <>{joinedComponents}<InlinePopover width={700} content={<>{columnValue[1]}<CopyButton value={columnValue[1]} /></>}/></>;
                }
            } else if (['calculationRequirements'].includes(columnValue[0]) && columnValue[1][0] === '{') {
                value = <>[object]<InlinePopover width={825} content={<>{<pre>{JSON.stringify(JSON.parse(columnValue[1]), undefined, 2)}</pre>}<CopyButton value={columnValue[1]} /></>}/></>;
            } else if (['rallyProductEventMap'].includes(columnValue[0]) && columnValue[1][0] === '{') {
                value = <>[object]<InlinePopover width={450} content={<>{<pre>{JSON.stringify(JSON.parse(columnValue[1]), undefined, 2)}</pre>}<CopyButton value={columnValue[1]} /></>}/></>;
            } else if (['campaignDefinitionId', 'questId', 'questDefinitionId'].includes(columnValue[0]) && columnValue[1][0] === '{') {
                value = <>[object]<CopyButton value={columnValue[1]} /><InlinePopover width={725} content={<>{<pre>{JSON.stringify(JSON.parse(columnValue[1]), undefined, 2)}</pre>}<CopyButton value={columnValue[1]} /></>}/></>;
            } else if (columnValue[0] === 'description' && columnValue[1].length > 11) {
                value = <>{columnValue[1].substring(0,6)}...<InlinePopover width={525} content={<>{columnValue[1]}<CopyButton value={columnValue[1]} /></>}/></>;
            } else if (['progressPercent', 'progressAmount'].includes(columnValue[0]) && columnValue[1].length > 10) {
                value = <>{columnValue[1].substring(0,8)}...<InlinePopover width={250} content={<>{columnValue[1]}<CopyButton value={columnValue[1]} /></>}/></>;
            }
        }
        return value;
    }), [entries, compactDeveloperColumns]);

    if(entries.length === 0) {
        return null;
    } else {
        entries[0][0] = pkRename;
    }

    return (
        <TableWrapper>
            <Table.Container>
                <Table.TableBody>
                    <HeaderRow>
                        {entries.map((columnValue, columnNumber) => (
                            <HeaderCell $divided key={columnNumber}>{columnValue[0]}</HeaderCell>
                        ))}
                    </HeaderRow>
                    <Table.Row>
                        {entriesValues.map((value, columnNumber) => (
                            <Cell $divided key={columnNumber}>{value}</Cell>
                        ))}
                    </Table.Row>
                </Table.TableBody>
            </Table.Container>
        </TableWrapper>
    );
};

export default SubChallengeDeveloperRow;
