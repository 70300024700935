import { Layout } from '@abyss/web/ui/Layout';
import { Heading } from '@abyss/web/ui/Heading';
import { Link } from '@abyss/web/ui/Link';
import { DataTable } from '@abyss/web/ui/DataTable';
// import { Drawer } from '@abyss/web/ui/Drawer';
import { Popover } from '@abyss/web/ui/Popover';


import { useCollapse } from '@abyss/web/hooks/useCollapse';
import { useDataTable } from '@abyss/web/hooks/useDataTable';
// import { useOverlay } from '@abyss/web/hooks/useOverlay';
import React from 'react';
import { dataFieldMap, fullNameMap } from '../constants/constants';

export default function TableResults({ props, currType }: { props: Record<string, string | object>[]; currType: string }) {

    const newData = createData();

    function createData() {
        const nestedData = processData(props);
        const flattenedData = nestedData && nestedData?.reduce((acc, value) => acc.concat(value), []);

        return flattenedData;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function processData(data: any): [] {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const records = data && data.map((record: any) => {
            // exceptions go here
            // if(record['calculationRequirements'] instanceof Object) {
            //     record['calculationRequirements'] = JSON.stringify(record['calculationRequirements']);
            // }
            return record;
        });

        return records;
    }

    const newColumns = React.useMemo(
        () =>
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        dataFieldMap.get(currType)?.map((field: any) => {
            return {
                Header: field,
                accessor: field,
                canToggleVisibilty: false,
                isHiddenByDefault: false,
                disableSortBy: false,
                canReorderColumn: false,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                Cell: ({ value }: { value: any }) => {
                    if (value === null || value === undefined) return '';
                    const isArray = Array.isArray(value);
                    const isBoolean = (typeof value === 'boolean');
                    if (isBoolean) return value.toString();
                    const isObject = (typeof value === 'object');
                    if (!isArray && !isObject) return value;
                    if (isArray && !value.length) return value;
                    if (isArray && typeof value[0] !== 'object') {
                        if (value.length == 1) return value;
                        return (
                            <div>
                                {isArray ? value[0] : value}
                                {isArray && (
                                    <Popover content={ value.map(v => <p key={v}>{v}</p>) } />)}
                            </div>
                        );
                    }
                    if (isArray) {
                        return (
                        <div>
                            <pre>{JSON.stringify(value[0], null, 2)}</pre>
                            {(<Popover content={<pre>{JSON.stringify(value, null, 2)}</pre>} />)}
                        </div>
                        );
                    }
                    return <pre>{JSON.stringify(value, null, 2)}</pre>;
                }
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                //TODO: set cell overflow drawer
                // Cell: ({ value } : any) => {
                //     const isArray = Array.isArray(value);
                //     if (isArray && !value.length) return '';
                //     return (
                //       <Layout.Group css={{ justifyContent: 'space-between' }}>
                //         {isArray ? value[0] : value}
                //         {isArray && (
                //           <Link
                //             size="$sm"
                //             onClick={() => drawer.open({ value })}
                //             aria-haspopup="dialog"
                //           >
                //             See All
                //           </Link>
                //         )}
                //       </Layout.Group>
                //     );
                //   },
            };
        }) ?? []
        ,
        []
    )

    const { collapseProps, buttonProps, isOpen } = useCollapse();

    const dataTableProps = useDataTable({
        initialData: newData,
        initialColumns: newColumns,

        showSelection: false,
        showPagination: newData.length > 10,
        pageSizeOptions: [5, 10, 25, 50, 100, 250, 500],
        pageSizeDefault: 10,
        showTopPagination: true,
        showBottomPagination: false,
        showGlobalFilter: newData.length > 3,
        showTableSettings: false, //true,
        highlightRowOnHover: true,
        showFullscreenButton: newData.length > 10,
        showDownloadButton: true,
        showFilterDataset: newData.length > 5,
        showColumnVisibilityConfig: newColumns.length > 8,
        initialFilters: [],

        uniqueStorageId: currType + '-data-table-usage'
    });

    // TODO: user overlay
    // const drawer = useOverlay('cellOverflow-drawer');
    // const { data: drawerData} = drawer.getState();

    // don't return empty table
    if (props.length > 0) {
        return (
            <React.Fragment>
                <Layout.Group>
                    <Heading display="h6" id={currType + '-dt'}>{fullNameMap.get(currType)?.replace(/\s+/g, '')} ({currType})</Heading>
                    <Link {...buttonProps}>{isOpen ? 'Collapse' : 'Expand'}</Link>
                </Layout.Group>

                <div {...collapseProps}>
                    <DataTable title={fullNameMap.get(currType)?.replace(/\s+/g, '')} tableState={dataTableProps} hideTitleHeader />
                </div>
                {/* TODO: add drawer for overflow data */}
                {/* <Drawer title="Cell Overflow Data" model="cellOverflow-drawer">
                    <Drawer.Section>
                    {drawerData &&
                        drawerData.value.map((item : any) => {
                        return (
                            <p key={item} style={{ marginBottom: 30 }}>
                            {item}
                            </p>
                        );
                        })}
                    </Drawer.Section>
                </Drawer> */}
                <br />
            </React.Fragment>
        );
    }
    return null;
}
