import { CampaignStartDateType, CampaignType, ChallengeView, RallyActivityId, SS_Campaign } from "../constants/challengeTypes";
import { isoDateTimeToReadableDate, isoDateTimeToReadableDateTime } from "./utils";

export const getName = (rallyActivityId: string, defaultResponse?: string) => {
    switch(rallyActivityId) {
        case RallyActivityId.ACTIVITY_5IN7:
            return 'Walk 10K steps or move 30 min 5/7 days';
        case RallyActivityId.DEVICE_PAIRED:
            return 'Pair Device';
        case RallyActivityId.DAILY_FITNESS_ACHIEVED:
            return 'Daily Fitness Achieved';
        case RallyActivityId.DAILY_FITNESS_PARTICIPATION:
            return 'Daily Fitness Participation';
        case RallyActivityId.FLU_SHOT:
            return 'Flu Shot Attestation';
        case RallyActivityId.SLEEP_5IN7:
            return 'Sleep 7 hours 5/7 days';
        case RallyActivityId.SLEEP_TRACKING:
            return 'Sleep 14 days';
        default:
            return defaultResponse ?? '-';
    }
}

export const getDescription = (campaign: SS_Campaign) => {
    switch(campaign.rallyActivityId) {
        case RallyActivityId.ACTIVITY_5IN7:
            return 'Walk 10K steps or move 30 min 5/7 days';
        case RallyActivityId.DAILY_FITNESS_ACHIEVED:
            return 'Walk 10K steps or move 30 min';
        case RallyActivityId.DAILY_FITNESS_PARTICIPATION:
            return 'Walk 5K steps or move 15 min';
        default:
            return campaign.qd?.description ?? '-';
    }
}

export const getType = (campaign: SS_Campaign) => {
    if(campaign.qd?.unit === 'SingleEvent') {
        return CampaignType.OTA;
    } else if(campaign.campaignType === 'DailyDefault') {
        return CampaignType.DAILY;
    } else {
        return CampaignType.CAMPAIGN;
    }
}

export const getLastCompletionDate = (campaign: SS_Campaign) => {
    const dates = campaign.quests.map((q) => {return q.mqp?.completionDateTime}).filter((d) => d !== undefined && d !== null) as string[];
    if(dates.length === 0) {
        return '-';
    }
    return isoDateTimeToReadableDate(dates.reduce((a, b) => a > b ? a : b)) ?? '-';
}

export const getLastCalculationDate = (campaign: SS_Campaign) => {
    const dates = campaign.quests.map((q) => {return q.mqp?.lastCalculationDateTime}).filter((d) => d !== undefined && d !== null) as string[];
    if(dates.length === 0) {
        return '-';
    }
    return isoDateTimeToReadableDateTime(dates.reduce((a, b) => a > b ? a : b)) ?? '-';
}

export const getStartFocusDate = (campaign: SS_Campaign) => {
    if(!campaign.isFocusExempt && campaign.mcf) {
        return `${CampaignStartDateType.FOCUS} ${isoDateTimeToReadableDate(campaign.mcf.startDateTime)}`;
    } else {
        const dates = campaign.quests.map((q) => {return q.startDateTime;}).filter((d) => d !== undefined && d !== null) as string[];
        if(dates.length === 0) {
            return '-';
        }
        return `${CampaignStartDateType.START} ${isoDateTimeToReadableDate(dates.reduce((a, b) => a < b ? a : b))}`;
    }
}

export const getActive = (campaign: SS_Campaign, type: string) => {
    switch(type) {
        case CampaignType.OTA:
            if(campaign.quests.at(0)?.mqp?.progressPercent ?? 0 > 1) {
                return 'Completed';
            } else
            if(campaign.isFocusExempt || campaign.mcf) {
                return 'Yes';
            } else {
                return 'No';
            }
        case CampaignType.DAILY:
            return 'Yes';
        case CampaignType.CAMPAIGN:
            if(!campaign.isFocusExempt && campaign.mcf) {
                return 'Yes';
            } else {
                return 'No';
            }
        default:
            return 'No';
    }
}

export const getProgress = (campaign: SS_Campaign, type: string) => {
    switch(type) {
        case CampaignType.OTA:
            return `${campaign.quests.at(0)?.mqp?.progressAmount ?? 0}/${campaign.quests.at(0)?.mqp?.goal ?? 1}`;
        case CampaignType.DAILY:
            return `${campaign.quests.filter((q) => (q.mqp?.progressPercent ?? 0) > 1.0).length}/${campaign.quests.length}`;
        case CampaignType.CAMPAIGN:
            return `${campaign.quests.filter((q) => (q.mqp?.progressPercent ?? 0) > 1.0).length}/${campaign.quests.length}`;
        default:
            return '-';
    }
}


export const getProgressCount = (campaign: SS_Campaign) => {
    const type = getType(campaign);
    switch(type) {
        case CampaignType.OTA:
            return campaign.quests.at(0)?.mqp?.progressAmount ?? 0;
        case CampaignType.DAILY:
            return campaign.quests.filter((q) => (q.mqp?.progressPercent ?? 0) > 1).length;
        case CampaignType.CAMPAIGN:
            return campaign.quests.filter((q) => (q.mqp?.progressPercent ?? 0) > 1).length;
        default:
            return 0;
    }
}

export const getProgressPercent = (progress: string) => {
    const progressPercent = Math.round((parseInt(progress.split('/')[0]) / parseInt(progress.split('/')[1]))*1000)/10;
    if(isNaN(progressPercent)) {
        return '-';
    }
    if(progressPercent >= 100) {
        return '100%';
    }
    return `${progressPercent}%`;
}

export const sortCampaign = (a: ChallengeView, b: ChallengeView) => {
    if(a.type === CampaignType.DAILY && b.type !== CampaignType.DAILY) {
        return -1;
    } else if(a.type !== CampaignType.DAILY && b.type === CampaignType.DAILY) {
        return 1
    } else if(a.type === CampaignType.OTA && b.type !== CampaignType.OTA) {
        return -1;
    } else if(a.type !== CampaignType.OTA && b.type === CampaignType.OTA) {
        return 1;
    } else {
        return a.name.localeCompare(b.name);
    };
}

export const sortDeveloperViewHeaders = (a: string, b: string) => {
    if(a === 'pk') {
        return -1;
    } else if (b === 'pk'){
        return 1;
    } else if (a === 'sk'){
        return -1;
    } else if (b === 'sk'){
        return 1;
    } else if (a === 'startDateTime'){
        return -1;
    } else if (b === 'startDateTime'){
        return 1;
    } else if (a === 'progressAmount' || a === 'progressValue'){
        return -1;
    } else if (b === 'progressAmount' || b === 'progressValue'){
        return 1;
    } else if (a === 'progressPercent' || a === 'progressPercentage'){
        return -1;
    } else if (b === 'progressPercent' || b === 'progressPercentage'){
        return 1;
    } else {
        return a.localeCompare(b);
    }
}

export const trimQuotes = (s: string) => {
    if (s.charAt(0) === '"' && s.charAt(s.length - 1) === '"')
    {
        return s.substring(1,s.length - 1);
    }
    return s;
}
