import axios, { AxiosError } from 'axios';
import qs from 'qs';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const processRecalc = async (token: string, naviId: string, dates: string[], toast: any, setIsLoading: React.Dispatch<React.SetStateAction<boolean>>) => {
    let success = true;

    for(const date of dates) {
        await axios.post(`${process.env.REACT_APP_INVOKE_URL}/recalcAPI`,
            qs.stringify({
                member_id: naviId,
                date: date,
            }), {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
        .then(() => {
            toast.clean();
            toast.show({
                message: `Recalculation successful on ${date}`,
                variant: 'success',
                autoClose: false
            });
        })
        .catch((response: Error | AxiosError) => {
            let message = `Recalculation error on ${date} so did not continue: `
            if (axios.isAxiosError(response)) {
                message += (response as AxiosError).response?.data;
            }
            else {
                message += (response as Error).message;
            }

            success = false;
            toast.clean();
            toast.show({
                message: message,
                variant: 'error',
                autoClose: false
            });
        })
        .finally(() => {
            setIsLoading(false);
        });

        if (!success) {
            break;
        }
    }
};
