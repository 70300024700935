import React from 'react';
import { Heading } from '@abyss/web/ui/Heading';
import { Layout } from '@abyss/web/ui/Layout';
import { Table } from '@abyss/web/ui/Table';
import { SS_MemberQuest } from '../constants/challengeTypes';
import { Cell, HeaderCell, HeaderRow } from '../styles/styles';

export interface SubChallengeActivityViewProps {
    quest: SS_MemberQuest;
};

const SubChallengeActivityView: React.FC<SubChallengeActivityViewProps> = ({ quest }) => {

    return (
        <Layout.Group space={8} alignItems='top'>
            <Layout.Space />
            <Layout.Space />
            <Layout.Space />
            <Layout.Space />
            <div>
                <Layout.Space />
                <Heading display='h6' color='$gray8'>Activities</Heading>
            </div>
            <Table.Container>
                <Table.TableHeader>
                    <HeaderRow>
                        <HeaderCell>Type</HeaderCell>
                        <HeaderCell>Date</HeaderCell>
                        <HeaderCell>Amount</HeaderCell>
                        <HeaderCell>Partner</HeaderCell>
                        <HeaderCell>Received</HeaderCell>
                        <HeaderCell>Transaction ID</HeaderCell>
                    </HeaderRow>
                </Table.TableHeader>
                <Table.TableBody>
                    {quest.activities.map((a, k) => {
                        return (
                            <Table.Row key={k}>
                                <Cell>{a.pk.split('#')[2]}</Cell>
                                <Cell>{a.sk}</Cell>
                                <Cell>{a.amount}</Cell>
                                <Cell>{a.partner}</Cell>
                                <Cell>{a.receivedDateTime}</Cell>
                                <Cell>{a.transactionId}</Cell>
                            </Table.Row>
                        );
                    })}
                </Table.TableBody>
            </Table.Container>
        </Layout.Group>
    );
};

export default SubChallengeActivityView;
