import { useLDClient } from 'launchdarkly-react-client-sdk';
import { Layout } from '@abyss/web/ui/Layout';
import { Tabs } from '@abyss/web/ui/Tabs';
import { MemberSearchResultsModel } from '../constants/types';
import MemberIdCard from './MemberIdCard';
import DeviceTypeCard from './DeviceTypeCard';
import DemographicsCard from './DemographicsCard';
import MemberAdditionalDataTable from './MemberAdditionalDataTable';
import ActivityRecalculationCard from './ActivityRecalculationCard';
import ChallengeViewTable from './ChallengeViewTable';

export interface MemberSearchResultsProps {
    data: MemberSearchResultsModel;
};

const MemberSearchResults: React.FC<MemberSearchResultsProps> = ({ data }) => {
    const ldClient = useLDClient();
    const showChallengeView = ldClient?.variation('release_ss_challenge_view', true) as boolean;
    const compactActions = ldClient?.variation('enable_ss_compact_actions_view', true) as boolean;
    const { memberIds, demographics, deviceTypePairDates, additionalData, plans } = data;

    return (
        <Layout.Stack space={8} alignLayout='left' grow>
            <Layout.Group space={8} alignItems='top'>
                <DemographicsCard member={demographics}/>
                <MemberIdCard member={memberIds} />
                <DeviceTypeCard deviceTypePairDates={deviceTypePairDates} />
            </Layout.Group>
            <Layout.Group space={8} grow>
                <MemberAdditionalDataTable additionalData={additionalData} />
            </Layout.Group>
            {!compactActions &&
                <Layout.Group space={8}>
                    <Tabs title='Member Actions' size='$sm'>
                        <Tabs.Tab label='Activity Recalculation'>
                            <ActivityRecalculationCard naviId={memberIds.naviId ?? ''}/>
                        </Tabs.Tab>
                    </Tabs>
                </Layout.Group>
            }
            {showChallengeView &&
                <ChallengeViewTable plans={plans} naviId={memberIds.naviId ?? ''}/>
            }
        </Layout.Stack>
    );
};

export default MemberSearchResults;
