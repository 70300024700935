import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';
import styled from 'styled-components';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { NavMenuPrimitives } from '@abyss/web/ui/NavMenu';
import { ToggleSwitch } from '@abyss/web/ui/ToggleSwitch';
import SheikahSlateLogo from './SheikahSlateLogo';
import HelpModal from './HelpModal';
import UserPreferenceModal from './UserPreferenceModal';
import { useUserRoleContext } from '../auth/UserRoleContext';
import InlinePopover from './InlinePopover';

const ResponsiveAppBar = () => {
    const auth = useAuth();
    const navigate = useNavigate();
    const { isBoth, isEngineer, setIsEngineer, setIsCSAdmin, isChecked, setIsChecked } = useUserRoleContext();

    const [isOpen, setIsOpen] = useState(false);
    const [isPreferencesOpen, setIsPreferencesOpen] = useState(false);
    /* eslint-disable @typescript-eslint/no-unused-vars */
    const [_anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);

    const handleOpenLD = () => {
        window.open(
            'https://app.launchdarkly.com/uhc-rewards/test/features',
            '_blank' // <- This is what makes it open in a new window.
        );
        setAnchorElNav(null);
    };

    const handleOnToggleChange = (e: { target: { checked: boolean } }) => {
        setIsChecked(e.target.checked);
        setIsEngineer(e.target.checked);
        setIsCSAdmin(!e.target.checked);
    };

    /* This component is what creates the HotBar at the top of the webpage */
    return (
        <NavMenuPrimitives.Provider positionViewportToTrigger={{ minWidth: 300 }}>
            <NavMenuPrimitives.Root zIndex={103} hasOverlay ariaLabel='Primitive Menu'>
                <NavMenuPrimitives.List ariaLabel='Sample Menu'>
                    <NavMenuPrimitives.Link onClick={() => navigate('/')} >
                        <SheikahSlateLogo size='40px' />
                    </NavMenuPrimitives.Link>
                    {isEngineer &&
                        <NavMenuPrimitives.Item>
                            <NavMenuPrimitives.Trigger>CLOUDWATCH</NavMenuPrimitives.Trigger>
                            <NavMenuPrimitives.Content>
                                <NavMenuPrimitives.Columns>
                                    <NavMenuPrimitives.Column
                                    >
                                        <NavMenuPrimitives.MenuItem
                                            title='DEV'
                                            href='https://us-east-1.console.aws.amazon.com/cloudwatch/home?region=us-east-1#dashboards:name=Sheikah-Slate-Dev-Env'
                                        />
                                        <NavMenuPrimitives.MenuItem
                                            title='INT'
                                            href='https://us-east-1.console.aws.amazon.com/cloudwatch/home?region=us-east-1#dashboards:name=Sheikah-Slate-Int-Env'
                                        />
                                        <NavMenuPrimitives.MenuItem
                                            title='TEST'
                                            href='https://us-east-1.console.aws.amazon.com/cloudwatch/home?region=us-east-1#dashboards:name=Sheikah-Slate-Test-Env'
                                        />
                                    </NavMenuPrimitives.Column>
                                </NavMenuPrimitives.Columns>
                            </NavMenuPrimitives.Content>
                        </NavMenuPrimitives.Item>
                    }
                    {isEngineer && (
                        <NavMenuPrimitives.Link onClick={() => handleOpenLD()}>
                            LaunchDarkly
                        </NavMenuPrimitives.Link>
                    )}
                    <NavMenuPrimitives.Link onClick={() => setIsOpen(true)}>
                        HELP
                        <IconSpacing>
                            <InlinePopover icon='new_releases' displayed={false}/>
                        </IconSpacing>
                    </NavMenuPrimitives.Link>
                    {isEngineer && (
                        <NavMenuPrimitives.Link onClick={() => navigate('/Dashboard')}>
                            PR Dashboard
                        </NavMenuPrimitives.Link>
                    )}
                    <NavMenuPrimitives.Link onClick={() => navigate('/Issues')}>
                        ISSUES
                    </NavMenuPrimitives.Link>
                    <NavMenuPrimitives.Link onClick={() => setIsPreferencesOpen(true)}>
                        <IconMaterial icon={"settings"} size={"$md"} variant='outlined' color={"white"}/>
                    </NavMenuPrimitives.Link>
                </NavMenuPrimitives.List>
                <NavMenuPrimitives.Link>
                    Signed in as: {auth.user?.profile.sub}{' '}
                </NavMenuPrimitives.Link>
                <NavMenuPrimitives.Link onClick={() => void auth.removeUser()}>
                    LOG OUT
                </NavMenuPrimitives.Link>
                {isBoth &&
                    <NavMenuPrimitives.Link>
                        CS
                        <div style={{marginLeft: '10px'}}><ToggleSwitch isChecked={isChecked} onChange={handleOnToggleChange}/></div>
                        Engineer
                    </NavMenuPrimitives.Link>
                }
                <HelpModal isOpen={isOpen} setIsOpen={setIsOpen} />
                <UserPreferenceModal isOpen={isPreferencesOpen} setIsOpen={setIsPreferencesOpen} />
                <NavMenuPrimitives.Viewport />
            </NavMenuPrimitives.Root>
        </NavMenuPrimitives.Provider>
    );
};

const IconSpacing = styled.span`
    margin-left: var(--abyss-space-xs);
`;

export default ResponsiveAppBar;
