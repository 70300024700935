import React from 'react'

const initialState = {
  compactDeveloperColumns: true,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  toggleCompactDeveloperColumns: () => {}
}

// https://vimalselvam.com/post/toggle-theme-using-react-hooks/
const UserPreferenceContext = React.createContext(initialState)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function UserPreferenceProvider({ children } : any) {
    const [compactDeveloperColumns, setCompactDeveloperColumns] = React.useState(true);

    React.useEffect(() => {
        const isCompactDeveloperColumns = localStorage.getItem('compactDeveloperColumns') === 'true'
        setCompactDeveloperColumns(isCompactDeveloperColumns)
    }, [compactDeveloperColumns])

    const toggleCompactDeveloperColumns = () => {
        const isCompactDeveloperColumns = !compactDeveloperColumns;
        localStorage.setItem('compactDeveloperColumns', JSON.stringify(isCompactDeveloperColumns));
        setCompactDeveloperColumns(isCompactDeveloperColumns);
    }

    return (
        <UserPreferenceContext.Provider value={{ compactDeveloperColumns, toggleCompactDeveloperColumns }}>
        {children}
        </UserPreferenceContext.Provider>
    )
}

export { UserPreferenceProvider, UserPreferenceContext }
