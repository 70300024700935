import axios, { AxiosError } from 'axios';
import qs from 'qs';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const fixRallyRelationship = async (token: string | undefined, naviId: string, toast: any, setIsLoading: React.Dispatch<React.SetStateAction<boolean>>) => {
    console.log(`Fixing Rally Relationship for ${naviId}`);
    toast.clean();
    setIsLoading(true);

    if(!token) {
        toast.show({
            message: 'User not authenticated',
            variant: 'error',
            autoClose: false
        });
        setIsLoading(false);
        return;
    }

    await axios.post(`${process.env.REACT_APP_INVOKE_URL}/recalcAPI`,
        qs.stringify({
            member_id: naviId,
            fixRallyRelationship: true
        }), {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    })
    .then(() => {
        toast.success('Successfully fixed member');
        toast.show({
            message: 'Successfully fixed member',
            variant: 'success',
            autoClose: false
        });
    })
    .catch((response: Error | AxiosError) => {
        let message = `Error fixing member's Rally Relationship: `
        if (axios.isAxiosError(response)) {
            message += (response as AxiosError).response?.data;
        }
        else {
            message += (response as Error).message;
        }
        toast.show({
            message: message,
            variant: 'error',
            autoClose: false
        });
    })
    .finally(() => {
        setIsLoading(false);
    });
};
