import { PropsWithChildren, createContext, useCallback, useContext, useMemo, useState } from 'react';
import { User } from 'oidc-client-ts';

interface IUserRoleContext {
    isEngineer: boolean;
    setIsEngineer: React.Dispatch<React.SetStateAction<boolean>>;
    isCSAdmin: boolean;
    setIsCSAdmin: React.Dispatch<React.SetStateAction<boolean>>;
    isBoth: boolean;
    setIsBoth: React.Dispatch<React.SetStateAction<boolean>>;
    isAuthorized: (user: User | null | undefined) => boolean;
    authorized: boolean;
    isChecked: boolean;
    setIsChecked: React.Dispatch<React.SetStateAction<boolean>>;
}

export const UserRoleContext = createContext<IUserRoleContext | null>(null);

const UserRoleProvider = ({ children }: PropsWithChildren<object>) => {
    const [isBoth, setIsBoth] = useState(false);
    const [isEngineer, setIsEngineer] = useState(false);
    const [isCSAdmin, setIsCSAdmin] = useState(false);
    const [authorized, setAuthorized] = useState(false);
    const [isChecked, setIsChecked] = useState(false);

    const isAuthorized = useCallback((user: User | null | undefined) => {
        const engineer_group = 'CN=UHCRewards_Engineers,CN=Users,DC=ms,DC=ds,DC=uhc,DC=com';
        const csadmin_group = 'CN=UHCRewards_CSAdmin,CN=Users,DC=ms,DC=ds,DC=uhc,DC=com';
        const user_groups: string[] = user?.profile.msad_groups as string[];
        const authorized_groups = process.env.REACT_APP_AUTH_GROUPS?.split(',').map(g => `CN=${g},CN=Users,DC=ms,DC=ds,DC=uhc,DC=com`) ?? [];

        const user_authorized_groups = user_groups.filter(g => authorized_groups.indexOf(g) >= 0);
        const authorized = user_authorized_groups.length > 0;
        const isEngineer = user_authorized_groups.includes(engineer_group);
        const isCSAdmin = user_authorized_groups.includes(csadmin_group);
        if(isEngineer && isCSAdmin) {
            setIsBoth(true);
            setIsEngineer(true);
            setIsChecked(true);
        } else if(isEngineer) {
            setIsEngineer(true);
        } else if(isCSAdmin) {
            setIsCSAdmin(true);
        }
        setAuthorized(authorized);
        return authorized;
    }, []);

    const contextValue: IUserRoleContext = useMemo(() => {
        return { isAuthorized, isEngineer, setIsEngineer, isCSAdmin, setIsCSAdmin, isBoth, setIsBoth, authorized, isChecked, setIsChecked };
    }, [isEngineer, isCSAdmin, isBoth, authorized, isChecked]);

    return <UserRoleContext.Provider value={contextValue}>{children}</UserRoleContext.Provider>;
};

export default UserRoleProvider;

export const useUserRoleContext = () => {
    const context = useContext(UserRoleContext);

    if (!context) {
        throw new Error('UserRoleContext not in scope.');
    }

    return context;
};
