import { MemberIdType } from '../constants/types';
import { NEW_RELEASES_GREEN, UPDATE_ORANGE } from '../constants/color';

const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];

export const isoDateTimeToIsoDate = (date?: string) => {
   if(date === undefined || date === null) return null;

   return date.substring(0, 10);
};
export const isoDateTimeToIsoDateTime = (date?: string) => {
   if(date === undefined || date === null) return null;

   return `${date.substring(0, 10)} ${date.substring(11, 19)}`;
};
export const isoDateTimeToReadableDate = (date?: string) => {
   if(date === undefined || date === null) return null;

   return `${monthNames[parseInt(date.substring(5, 7)) - 1]} ${parseInt(date.substring(8, 10))}, ${date.substring(0, 4)}`;
};
export const isoDateTimeToReadableDateTime = (date?: string) => {
   if(date === undefined || date === null) return null;

   let hour = parseInt(date.substring(11, 13));
   const morning = hour < 12 ? 'AM' : 'PM';
   if(hour === 0) {
      hour = 12;
   } else if (hour > 12) {
      hour = hour - 12;
   }

   return `${monthNames[parseInt(date.substring(5, 7)) - 1]} ${parseInt(date.substring(8, 10))}, ${date.substring(0, 4)} ${hour}${date.substring(13, 19)} ${morning}`;
};

export const iconColor = (icon: string) => {
   switch(icon) {
      case 'info':
      case 'help_outline':
         return '$interactive1';
      case 'new_releases':
         return NEW_RELEASES_GREEN;
      case 'update':
         return UPDATE_ORANGE;
      case 'check_circle':
         return '$success1';
      case 'warning':
      case 'warning_amber':
      case 'error_outline':
         return '$error1';
      default:
         return '$interactive1';
   }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const autoIdType = (id : string, form: any) => {
   if(id === null || id === undefined || id === '') return;
   if(form.getValues('idType') !== MemberIdType.RALLY_ID && (id.startsWith('RR#') || (!id.includes('M_') && id.match(/[0-9A-Za-z]{4}-[0-9A-Za-z]{4}-/)))) {
       form.setValue('idType', MemberIdType.RALLY_ID);
       return;
   }
   if(form.getValues('idType') !== MemberIdType.NAVI_ID && (id.startsWith('M_') || id.includes('#M_'))) {
       form.setValue('idType', MemberIdType.NAVI_ID);
       return;
   }
   if(form.getValues('idType') !== MemberIdType.EID && (id.startsWith('ER#') || (id.match(/^[0-9]+$/) && id.length > 7))) {
       form.setValue('idType', MemberIdType.EID);
       return;
   }
};
