import React from 'react';
import styled from 'styled-components';
import { Table } from '@abyss/web/ui/Table';
import { SS_Campaign } from '../constants/challengeTypes';
import SubChallengeDeveloperRow from './SubChallengeDeveloperRow';
import SubChallengeQuestDeveloperRow from './SubChallengeQuestDeveloperRow';

export interface SubChallengeDeveloperViewProps {
    campaign: SS_Campaign;
};

const SubChallengeDeveloperView: React.FC<SubChallengeDeveloperViewProps> = ({ campaign }) => {
    const { quests = [] } = campaign;

    return (
        <Table.Row>
            <Table.Cell colSpan={100} style={{maxWidth: '98vw', overflow: 'scroll'}}>
                <OverflowWrapper>
                    <SubChallengeDeveloperRow record={campaign.row} pkRename='pk.CD' />
                    <SubChallengeDeveloperRow record={campaign.mcf?.row} pkRename='pk.MCF' />
                    <SubChallengeDeveloperRow record={campaign.mcp?.row} pkRename='pk.MCP' />
                    {quests.length > 0 &&
                        <SubChallengeQuestDeveloperRow quests={quests} />
                    }
                </OverflowWrapper>
            </Table.Cell>
        </Table.Row>
    );
};

const OverflowWrapper = styled.div`
    overflow: scroll;
    max-width: 100%;
    width:100%;
    max-height: 74vh;
    white-space: nowrap;
`;

export default SubChallengeDeveloperView;
