import { FC } from 'react';
import { useAuth } from 'react-oidc-context';
import { ThemeProvider } from '@abyss/web/ui/ThemeProvider';
import { Layout } from '@abyss/web/ui/Layout';
import { Heading } from '@abyss/web/ui/Heading';
import { PageBody } from '@abyss/web/ui/PageBody';
import { Button } from '@abyss/web/ui/Button';
import SheikahSlateLogo from '../components/SheikahSlateLogo';

const Login: FC = () => {
    const auth = useAuth();

    return (
        <ThemeProvider>
            <PageBody>
                <Layout.Stack alignLayout="center">
                    <Layout.Space />
                    <Heading>Login to <span style={{ color: '#7FBFED' }}> Sheikah</span> Slate</Heading>
                    <SheikahSlateLogo size="150px" />
                    <Button onClick={() => void auth.signinRedirect()}>
                        Login
                    </Button>
                </Layout.Stack>
            </PageBody>
        </ThemeProvider>
    );
};

export default Login;
