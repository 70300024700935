/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { useState } from 'react';
import { Button } from '@abyss/web/ui/Button';
import { TextInput } from '@abyss/web/ui/TextInput';
import Cookies from 'js-cookie';
import { TopMarginWrapper } from '../styles/styles';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function TokenInput({ setToken, setLoading }: any) {
    const [value, setValue] = useState('');

    const onSubmit = () => {
        setToken(value);
        Cookies.set('githubToken', value);
        setLoading(true);
    };

    return (
        <React.Fragment>
            <TextInput label='Input GitHub Token' placeholder='ghp_' value={value} isClearable onChange={(e: any) => setValue(e.target.value)} onClear={() => setValue('')} />
            <TopMarginWrapper>
                <Button onClick={onSubmit}>
                    Submit
                </Button>
            </TopMarginWrapper>
        </React.Fragment>
    );
}
