import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/globals.css';
import App from './App';
import { AuthProvider } from 'react-oidc-context';
import { User } from 'oidc-client-ts';
import { OverlayProvider } from '@abyss/web/ui/OverlayProvider';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

function throwUndefinedError(envVarName: string): never {
    throw new Error('Undefined environment variable: ' + envVarName);
}

const oidcConfig = {
    authority: process.env.REACT_APP_AUTH_AUTHORITY ?? throwUndefinedError('REACT_APP_AUTH_AUTHORITY'),
    metadataUrl: process.env.REACT_APP_AUTH_WELL_KNOWN_URL ?? throwUndefinedError('REACT_APP_AUTH_WELL_KNOWN_URL'),
    client_id: process.env.REACT_APP_AUTH_CLIENT_ID ?? throwUndefinedError('REACT_APP_AUTH_CLIENT_ID'),
    client_secret: process.env.REACT_APP_AUTH_CLIENT_SECRET ?? throwUndefinedError('REACT_APP_AUTH_CLIENT_SECRET'),
    redirect_uri: process.env.REACT_APP_AUTH_REDIRECT_URI ?? throwUndefinedError('REACT_APP_AUTH_REDIRECT_URI'),
    acr_values: process.env.REACT_APP_AUTH_ACR_VALUES ?? throwUndefinedError('REACT_APP_AUTH_ACR_VALUES'),
    automaticSilentRenew: true,
    scope: 'openid profile address email phone',
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onSigninCallback: (_user: User | void): void => {
        // eslint-disable-line @typescript-eslint/no-unused-vars
        window.history.replaceState({}, document.title, window.location.pathname);
    }
};

(async () => {
    const LDProvider = await asyncWithLDProvider({
        clientSideID: process.env.REACT_APP_LD_CLIENT_ID ?? throwUndefinedError('REACT_APP_LD_CLIENT_ID')
    });

    root.render(
        <React.StrictMode>
            <LDProvider>
                <AuthProvider {...oidcConfig}>
                    <OverlayProvider>
                        <App />
                    </OverlayProvider>
                </AuthProvider>
            </LDProvider>
        </React.StrictMode>
    );
})();
