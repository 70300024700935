import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { Layout } from '@abyss/web/ui/Layout';
import { Heading } from '@abyss/web/ui/Heading';
import { Link } from '@abyss/web/ui/Link';
import { Table } from '@abyss/web/ui/Table';
import { SelectInput } from '@abyss/web/ui/SelectInput';
import { useCollapse } from '@abyss/web/hooks/useCollapse';
import { SS_MemberPlan, ChallengeView } from '../constants/challengeTypes';
import { getName, getType, getDescription, getProgress, getStartFocusDate, getLastCompletionDate, getActive, getProgressPercent, sortCampaign } from '../helpers/challengeUtils';
import { isoDateTimeToIsoDate } from '../helpers/utils';
import ChallengeViewRow from './ChallengeViewRow';
import { Cell, HeaderCell, HeaderRow } from '../styles/styles';

export interface ChallengeViewTableProps {
    plans: SS_MemberPlan[];
    naviId: string;
};

const ChallengeViewTable: React.FC<ChallengeViewTableProps> = ({ plans, naviId }) => {
    const currentPlan = useMemo(() => {
        return plans.find(({adjustedEffectiveDateTime, adjustedSkEndDateTime}) => adjustedEffectiveDateTime <= new Date().toISOString() && adjustedSkEndDateTime >= new Date().toISOString()) ?? plans[plans.length - 1];
    }, [plans]);
    const [selectedPlan, setSelectedPlan] = useState(currentPlan?.sk);
    const { collapseProps, buttonProps, isOpen } = useCollapse();
    const planOptions = useMemo(() => {
        return plans.map(({sk, adjustedEffectiveDateTime, adjustedSkEndDateTime}) => ({ label: `${isoDateTimeToIsoDate(adjustedEffectiveDateTime)} - ${isoDateTimeToIsoDate(adjustedSkEndDateTime)}`, value: sk }));
    }, [plans]);

    const challenges : ChallengeView[] | undefined = useMemo(() => {
        return plans.find(({ sk }) => sk === selectedPlan)?.campaigns.map((c) => {
            const type = getType(c);
            const progress = getProgress(c, type);
            const progressPercent = getProgressPercent(progress);
            const active = currentPlan?.sk !== selectedPlan ? 'No' : getActive(c, type);
            return ({
                id: c.id,
                name: getName(c.rallyActivityId, c.qd?.name),
                description: getDescription(c),
                type: type,
                progress: progress,
                progressPercent: progressPercent === '100%' ? <GreenBackgroundWrapper>{progressPercent}</GreenBackgroundWrapper> : progressPercent,
                active: active === 'Yes' ? <GreenWordWrapper>{active}</GreenWordWrapper> : active,
                startFocusDate: getStartFocusDate(c),
                lastCompletionDate: getLastCompletionDate(c),
                campaign: c,
            })
        })
        .sort((a, b) =>
            sortCampaign(a, b)
        );
    }, [selectedPlan, plans, currentPlan?.sk]);

    if(plans.length === 0) {
        return (
            <Layout.Stack alignItems='left'>
                <Layout.Group grow>
                    <Heading display='h5'>Challenges</Heading>
                </Layout.Group>
                <Layout.Group grow>
                    <div>No plans found</div>
                </Layout.Group>
            </Layout.Stack>
        );
    }

    return (
        <Layout.Stack alignItems='left' grow>
            <Layout.Group>
                <Heading display='h5'>Challenges</Heading>
                <Link {...buttonProps}>{isOpen ? 'Collapse' : 'Expand'}</Link>
                <LabelWrapper>
                    Plan Year
                    <DropdownWrapper>
                        <SelectInput
                            label='Plan Year'
                            hideLabel
                            validators={{ required: true }}
                            value={selectedPlan}
                            onChange={(value : string) => {
                                return setSelectedPlan(value);
                            }}
                            options={planOptions}
                            width={225}
                        />
                    </DropdownWrapper>
                </LabelWrapper>
            </Layout.Group>

            <div {...collapseProps}>
                <Table.Container>
                    <Table.TableHeader>
                        <HeaderRow>
                            <HeaderCell></HeaderCell>
                            <HeaderCell>Name</HeaderCell>
                            <HeaderCell>Description</HeaderCell>
                            <HeaderCell>Activity</HeaderCell>
                            <HeaderCell colSpan={2}>Progress</HeaderCell>
                            <HeaderCell>Active</HeaderCell>
                            <HeaderCell>Start/Enroll Date</HeaderCell>
                            <HeaderCell colSpan={2}>Last Completion Date</HeaderCell>
                        </HeaderRow>
                    </Table.TableHeader>
                    <Table.TableBody>
                        {challenges && challenges.length > 0 ? challenges.map((c, i) => (
                            <ChallengeViewRow challenge={c} naviId={naviId} key={i} />
                        )) :
                            <Table.Row>
                                <Cell colSpan={10}>No challenge data available</Cell>
                            </Table.Row>
                        }
                    </Table.TableBody>
                </Table.Container>
            </div>
        </Layout.Stack>
    );
};

const DropdownWrapper = styled.div`
    display: inline-block;
    margin-left: 10px;
`;

const LabelWrapper = styled.div`
    display: inline-block;
    margin-left: 30px;
    font-weight: bold;
`;

const GreenWordWrapper = styled.div`
    color: green;
`;

const GreenBackgroundWrapper = styled.div`
    background-color: green;
    color: white;
    padding: 0px 3px;
`;

export default ChallengeViewTable;
