
export const utcToNaiveStartDate = (dateString: string) => {
   const date = new Date(Date.parse(dateString));
   date.setUTCHours(0);
   date.setUTCMinutes(0);
   date.setUTCSeconds(0);
   return date.toISOString().replace('.000Z', '');
};

export const utcToNaiveEndDate = (dateString: string) => {
   const date = new Date(Date.parse(dateString));
   date.setUTCHours(0);
   date.setUTCDate(date.getUTCDate() - 1);
   date.setUTCHours(23);
   date.setUTCMinutes(59);
   date.setUTCSeconds(59);
   return date.toISOString().replace('.000Z', '');
};
