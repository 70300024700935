import { useEffect } from 'react';
import { useAuth } from 'react-oidc-context';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Button } from '@abyss/web/ui/Button';
import { Layout } from '@abyss/web/ui/Layout';
import Home from './pages/Home';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import Issues from './pages/Issues';
import UserRoleProvider, { useUserRoleContext } from './auth/UserRoleContext';
import { UserPreferenceProvider } from './contexts/UserPreferenceContext';

function RawApp() {
    const { isAuthorized, authorized } = useUserRoleContext();
    const auth = useAuth();

    useEffect(() => {
        if (auth.isAuthenticated && auth.user) {
            isAuthorized(auth.user);
        }
    }, [auth.isAuthenticated, auth.user]);

    //For development
    const authToggle = JSON.parse(process.env.REACT_APP_AUTH_TOGGLE || 'false');
    if (!authToggle) {
        return (
            <BrowserRouter>
                <Routes>
                    <Route index element={<Home />} />
                    <Route path='/Dashboard' element={<Dashboard />} />
                    <Route path='/Issues' element={<Issues />} />
                </Routes>
            </BrowserRouter>
        );
    }

    switch (auth.activeNavigator) {
        case 'signinSilent':
            return <div>Signing you in...</div>;
        case 'signoutRedirect':
            return <div>Signing you out...</div>;
    }

    if (auth.isLoading) {
        return <div>Loading...</div>;
    }

    if (auth.error) {
        return (
            <Layout.Stack alignLayout="center">
                <p>Failed to authenticate. Error: {auth.error.message}</p>
                <Button onClick={() => { auth.removeUser(); window.location.href='/' }}>Go to Login</Button>
            </Layout.Stack>
        );
    }

    if (auth.isAuthenticated) {
        if (authorized) {
            return (
               <BrowserRouter>
                    <Routes>
                        <Route index element={<Home />} />
                        <Route path='/Dashboard' element={<Dashboard />} />
                        <Route path='/Issues' element={<Issues />} />
                    </Routes>
                </BrowserRouter>
            );
        } else {
            return <p>Not authorized.</p>;
        }
    }
    return <Login />;
}

const App = () => (
    <UserRoleProvider>
        <UserPreferenceProvider>
            <RawApp />
        </UserPreferenceProvider>
    </UserRoleProvider>
);
export default App;
