import copy from "copy-to-clipboard";
import styled from 'styled-components';
import { useToast } from '@abyss/web/hooks/useToast';
import { Button } from '@abyss/web/ui/Button';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';

export interface CopyButtonProps {
    value?: string;
    displayed?: boolean;
};

const CopyButton: React.FC<CopyButtonProps> = ({ value, displayed }) => {
    const { toast } = useToast();

    const copyToClipboard = (value: string) => {
        copy(value);
        toast.show({
            message: 'Copied to clipboard',
            variant: 'success',
            autoClose: 1250
        });
    }
    return (
        <>
            {displayed && value &&
                <CopyButtonWrapper>
                    <Button size='$xs' variant='tertiary' onClick={() => copyToClipboard(value)}>
                        <IconMaterial size='$sm' icon="content_copy"/>
                    </Button>
                </CopyButtonWrapper>
            }
        </>
    );
};

const CopyButtonWrapper = styled.span`
    margin-left: var(--abyss-space-xs);
    border-radius: 0px;
`;

CopyButton.defaultProps = {
    value: undefined,
    displayed: true,
};

export default CopyButton;
