import styled from 'styled-components';
import { Card } from '@abyss/web/ui/Card';
import { Table } from '@abyss/web/ui/Table';
import { DeviceTypePairDates } from '../constants/types';
import { isoDateTimeToIsoDateTime } from '../helpers/utils';
import { Cell, HeaderCell, HeaderRow } from '../styles/styles';

export interface DeviceTypeCardProps {
    deviceTypePairDates: DeviceTypePairDates[];
};

const DeviceTypeCard: React.FC<DeviceTypeCardProps> = ({ deviceTypePairDates }) => {
    return (
        <SizeWrapper>
            <Card size='small'>
                <Table.Container title='Device Types Table'>
                    <Table.TableHeader>
                        <HeaderRow>
                            <HeaderCell>Data Type</HeaderCell>
                            <HeaderCell>Partner</HeaderCell>
                            <HeaderCell>First Pair Date</HeaderCell>
                            <HeaderCell>Latest Pair Date</HeaderCell>
                        </HeaderRow>
                    </Table.TableHeader>
                    <Table.TableBody>
                        {deviceTypePairDates.length > 0 ? deviceTypePairDates.map((d, i) => (
                            <Table.Row key={i}>
                                <Cell>{d.dataType}</Cell>
                                <Cell>{d.partner}</Cell>
                                <Cell>{isoDateTimeToIsoDateTime(d.firstPairDate)}</Cell>
                                <Cell>{isoDateTimeToIsoDateTime(d.latestPairDate)}</Cell>
                            </Table.Row>
                        )) :
                            <Table.Row>
                                <Cell colSpan={4}>No pair data available</Cell>
                            </Table.Row>
                        }
                    </Table.TableBody>
                </Table.Container>
            </Card>
        </SizeWrapper>
    );
};

const SizeWrapper = styled.main`
    min-width: 519px;
`;

export default DeviceTypeCard;
