import { Table } from '@abyss/web/ui/Table';
import { MemberAdditionalData } from '../constants/types';
import { isoDateTimeToIsoDate, isoDateTimeToIsoDateTime, isoDateTimeToReadableDate, isoDateTimeToReadableDateTime } from '../helpers/utils';
import InlinePopover from './InlinePopover';
import { Cell, HeaderCell, HeaderRow } from '../styles/styles';

export interface MemberAdditionalDataTableProps {
    additionalData: MemberAdditionalData;
};

const MemberAdditionalDataTable: React.FC<MemberAdditionalDataTableProps> = ({ additionalData }) => {
    const { lastActivityReceived, lastSyncDate, lastCompletedQuest, assignedUpToDate } = additionalData;
    return (
        <Table.Container title='Member Additional Details Table'>
            <Table.TableHeader>
                <HeaderRow>
                    <HeaderCell>Last Activity Received</HeaderCell>
                    <HeaderCell>Last Sync Date</HeaderCell>
                    <HeaderCell>Last Completed Quest</HeaderCell>
                    <HeaderCell>Assigned Up To Date</HeaderCell>
                </HeaderRow>
            </Table.TableHeader>
            <Table.TableBody>
                <Table.Row>
                    <Cell>
                        {isoDateTimeToReadableDate(lastActivityReceived?.date) ?? '-'}
                        <InlinePopover content={isoDateTimeToIsoDateTime(lastActivityReceived?.date)} displayed={lastActivityReceived?.date ? true : false} />
                    </Cell>
                    <Cell>
                        {isoDateTimeToReadableDateTime(lastSyncDate) ?? '-'}
                        <InlinePopover content={isoDateTimeToIsoDateTime(lastSyncDate)} displayed={lastSyncDate ? true : false} />
                    </Cell>
                    <Cell>
                        {isoDateTimeToReadableDate(lastCompletedQuest?.date) ?? '-'}
                        <InlinePopover
                            title={lastCompletedQuest?.name}
                            content={isoDateTimeToIsoDateTime(lastCompletedQuest?.date)}
                            displayed={lastCompletedQuest?.date ? true : false}
                        />
                    </Cell>
                    <Cell>
                        {isoDateTimeToReadableDate(assignedUpToDate) ?? '-'}
                        <InlinePopover content={isoDateTimeToIsoDate(assignedUpToDate)} displayed={assignedUpToDate ? true : false} />
                    </Cell>
                </Table.Row>
            </Table.TableBody>
        </Table.Container>
    );
};

export default MemberAdditionalDataTable;
