import { FC, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import HotBar from '../components/HotBar';
import { PageBody } from '@abyss/web/ui/PageBody';
import { PageBodyIntro } from '@abyss/web/ui/PageBodyIntro';
import { TextInput } from '@abyss/web/ui/TextInput';
import { TextInputArea } from '@abyss/web/ui/TextInputArea';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { Button } from '@abyss/web/ui/Button';
import { useForm } from '@abyss/web/hooks/useForm';
import { ThemeProvider } from '@abyss/web/ui/ThemeProvider';
import { Modal } from '@abyss/web/ui/Modal';
import { TopMarginWrapper } from '../styles/styles';

const Issues: FC = () => {
    const auth = useAuth();
    const [submitted, setSubmitted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errorState, setErrorState] = useState(false);

    const token = auth.user?.access_token;
    const apiUrl = process.env.REACT_APP_INVOKE_URL;

    const defaultValues = {
        submitter: auth.user?.profile.sub
    };

    const form = useForm({
        defaultValues: defaultValues
    });

    const resetForm = () => {
        form.reset(defaultValues)
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const submitIssue = async (data : any, e : Event) => {
        e.preventDefault();
        try {
            setIsLoading(true);
            const response = await fetch(`${apiUrl}/submitIssue`, {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                }
            });
            setIsLoading(false);
            if (response.status != 200) {
                setErrorState(true);
            } else {
                resetForm();
                setSubmitted(true);
            }
        } catch (error) {
            setIsLoading(false);
            setErrorState(true);
        }
    };

    return (
        <ThemeProvider>
            <HotBar></HotBar>
            <PageBody>
                <PageBodyIntro.Row>
                    <PageBodyIntro.Title>Submit an issue</PageBodyIntro.Title>
                </PageBodyIntro.Row>
                <FormProvider state={form} onSubmit={submitIssue} onError={null}>
                    <TextInput
                        label="Title"
                        placeholder="Title"
                        model="title"
                        isClearable
                        maxLength={200}
                        validators={{ required: true }}
                    />
                    <TextInputArea
                        label="Provide a description of the issue"
                        placeholder="I'm having a problem with xyz..."
                        model="description"
                        isClearable
                        validators={{ required: true }}
                    />
                    <TextInput
                        label="Submitter"
                        model="submitter"
                        isDisabled={auth.user?.profile.sub != undefined}
                        validators={{ required: true }}
                    />
                    <TopMarginWrapper>
                        <Button type="submit" isLoading={isLoading} ariaLoadingLabel="Recalculating">
                            Submit
                        </Button>
                    </TopMarginWrapper>
                </FormProvider>
                <Modal
                    title="Success"
                    isOpen={submitted}
                    onClose={() => setSubmitted(false)}
                >
                    <Modal.Section>Your issue was submitted.</Modal.Section>
                </Modal>
                <Modal
                    title="Oh no!"
                    isOpen={errorState}
                    onClose={() => setErrorState(false)}
                >
                    <Modal.Section>There was an error submitting the issue.</Modal.Section>
                </Modal>
            </PageBody>
        </ThemeProvider>
    );
};
export default Issues;
