import React, { FC } from 'react';
import HotBar from '../components/HotBar';
import PRDashboard from '../components/PRDashboard';
import TokenInput from '../components/TokenInput';
import Cookies from 'js-cookie';
import { ThemeProvider } from '@abyss/web/ui/ThemeProvider';
import { Heading } from '@abyss/web/ui/Heading';
import { PageBody } from '@abyss/web/ui/PageBody';
import { Button } from '@abyss/web/ui/Button';

const Dashboard: FC = () => {
    const [token, setToken] = React.useState('');
    const [isLoading, setLoading] = React.useState(true);

    React.useEffect(() => {
        const cookieToken = Cookies.get('githubToken'); // get the value of the 'githubToken' cookie
        if (cookieToken && !token) {
            setToken(cookieToken); // set the 'token' state to the value of the cookie
        }
    }, []); // only run once when the component mounts

    const onSubmit = React.useCallback(() => {
        setToken('');
        Cookies.remove('githubToken');
    }, []);

    return (
        <ThemeProvider>
            <HotBar></HotBar>
            <PageBody fullWidth>
                <div style={{ marginTop: '15px', marginBottom: '50px', marginRight: '130px', marginLeft: '130px' }}>
                    <Heading display={1}>
                        Pull Request Dashboard
                    </Heading>
                    {!isLoading && token && (
                        <Button style={{ position: 'absolute', right: 150, marginTop: '-30px' }} onClick={onSubmit} size='$sm' variant='destructive'>
                            Reset Token
                        </Button>
                    )}
                    {!token && <TokenInput setToken={setToken} setLoading={setLoading} />}
                    {token && <PRDashboard inputToken={{ value: token }} isLoading={isLoading} setLoading={setLoading} />}
                </div>
            </PageBody>
        </ThemeProvider>
    );
};

export default Dashboard;
